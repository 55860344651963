import { useEffect, useState } from "react";
import { ModelPizzas } from "./Model/ModelPizzas";

function MenuCG(nomeMenu: string, jsonMenu: string) {
  const [menuData, setMenuData] = useState<ModelPizzas[]>([]);

  useEffect(() => {
    if (menuData.length === 0) {
      fetch(
        "https://api.menu.jsmotta.com/api/v1/mnu/OpenMenu/GetJSon?categoria=Pizzas",
        { cache: "no-store" }
      )
        .then((response) => response.json())
        .then((data) => {
          const dados = data as ModelPizzas[];

          const sortedData = dados.sort((a: any, b: any) =>
            a.descricao.localeCompare(b.descricao)
          );
          sortedData.map((menu: any, index: any) => (menu.selecionado = false));
          setMenuData(sortedData);
        });
    }
  }, []);

  const [menuDataVisible, setmenuDataVisible] = useState(true);

  const togglemenuData = () => {
    setmenuDataVisible(!menuDataVisible);
  };

  const showMe =
    "panel-collapse" +
    (menuDataVisible ? "collapse show openMainOpen" : "collapse");
  const showMeCaption = menuDataVisible ? "openMainOpen" : "collapsed";
  const menuDataVisibleYN = menuDataVisible ? "true" : "false";

  const [traduzido, setTraduzido] = useState<string[]>([]);
  const rowSelected: string = "row colorMenuSelected";
  const rowUnselected: string = "row";

  const changeMenu = (menu: ModelPizzas) => () => {
    const newMenuData = menuData.map((item) => {
      if (item === menu) {
        item.selecionado = !item.selecionado;
      }
      return item;
    });
    setTraduzido(new Array(newMenuData.length).fill(""));
    setMenuData(newMenuData);
  };

  const showMenuPt = (id: number) => () => {
    if (window.location.href.startsWith("https://menu.jsmotta.com.br/")) {
      return;
    }

    fetch("https://api.menu.jsmotta.com/api/v1/mnu/OpenMenu/getPizza?id=" + id, {
      cache: "no-store",
    })
      .then((response) => response.text())
      .then((text) =>
        menuData.forEach((item, index) => {
          if (item.id === id) {
            item.traduzido = text;
            setTraduzido((traduzido) => {
              const newTraduzido = [...traduzido];
              newTraduzido[index] = text;
              return newTraduzido;
            });
          }
        })
      )
      .catch((error) => {
        console.error("Erro ao buscar tradução:", error);
      });
  };

  return (
    <div>
      <div className="panel-heading" role="tab" id="headingEighteen">
        <h4 className="panel-title">
          <a
            onClick={togglemenuData}
            className={showMeCaption}
            data-toggle="collapse"
            data-parent="#accordion"
            href="javascript:void(0);"
            aria-expanded={menuDataVisibleYN}
            aria-controls="collapseEighteen"
          >
            {nomeMenu}
            <span translate="no" className="hidePt">
              &nbsp;{nomeMenu}
              </span>
          </a>
        </h4>
      </div>
      <div
        id="collapseEighteen"
        className={showMe}
        role="tabpanel"
        aria-labelledby="headingEighteen"
      >
        <div className="panel-body">
          <div className="openMenu">
            {menuDataVisible}{" "}
            {
              <div className="row">
                <div className="col-md-8 col-8">
                  <p></p>
                </div>
                <div className="col-md-2 col-2 text-right">
                  <p>meia</p>
                </div>
                <div className="col-md-2 col-2 text-right">
                  <p>inteira</p>
                </div>
              </div>
            }
            {menuDataVisible &&
              menuData
                .sort((a, b) => a.nome.localeCompare(b.nome))
                .map((menu, index) => (
                  <div
                    onMouseUp={changeMenu(menu)}
                    className={
                      menuData[index].selecionado ? rowSelected : rowUnselected
                    }
                    key={index}
                  >
                    <div className="col-md-8 col-8">
                      <p onMouseUp={showMenuPt(menuData[index].id)}>
                        {menu.nome}
                        <br />
                        <i>{menu.descricao}</i>
                      </p> 
                        <span className="breakOrigemPt" translate="no">
                          {traduzido[index] && traduzido[index].length > 0
                            ? traduzido[index]
                            : ""}                      
                      </span>
                    </div>
                    <div className="col-md-2 col-2 text-right">
                      <p>{!menu.meia?.startsWith("0,00") && menu.meia}</p>
                    </div>
                    <div className="col-md-2 col-2 text-right">
                      <p>{!menu.grande?.startsWith("0,00") && menu.grande}</p>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MenuCG;
