import React from "react";
import BaseMenu from "./baseMenu";
import { getHours } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { pt } from "date-fns/locale";

export const nomeHappy = () : string => {
  // Get current time in Portugal timezone
  const portugalTimeZone = "Europe/Lisbon";
  const utcDate = new Date();
  const portugalDate = toZonedTime(utcDate, portugalTimeZone);
  const currentHour = getHours(portugalDate);

  let nome = "Happy Hour (Cervejas: 17 às 20h - Cocktails: 20 às 21h)";

  if (currentHour < 17) {
    if (currentHour > 14 && currentHour <= 15) {
      nome += " Começa às 17h🥳";
    }
    else if (currentHour > 15) {
        nome += " Começa em breve🥳";
      }
  }

  if (currentHour > 21) {
    nome += " Por hoje acabou😞! Amanhã tem mais🥳";
  }

  if (currentHour >= 17 && currentHour < 20) {
    nome += " Está a decorrer🥳";
  }

  return nome;
}

const MenuHappy: React.FC = () => {
  return <BaseMenu nome={nomeHappy()} file="Happyhour" />;
};

export default MenuHappy;
